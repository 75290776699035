import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CheckCircle, Clipboard, Check, InfoIcon } from "lucide-react";
import { Button } from "@radix-ui/themes";
import * as Progress from "@radix-ui/react-progress";
import { useSalesContext } from "../../contexts/SalesContext";
import { useNavigate } from "react-router-dom";

export default function Component({ orderInfo = {} }) {
  const {
    newOrderTotal,
    progress,
    setShouldRunNewShopLeadGeneration,
    shortId,
    resetData,
    setProgress,
    isDataComplete,
    newOrderData,
  } = useSalesContext();
  const navigate = useNavigate();

  const [copy, setCopy] = useState(false);
  const [showMainContent, setShowMainContent] = useState(false);
  const [showNoInventoryContent, setShowNoInventoryContent] = useState(false);
  const [showErrorContent, setShowErrorContent] = useState(false);
  const [orderPlacementAttempt, setOrderPlacementAttempt] = useState(true);

  useEffect(() => {
    if (orderPlacementAttempt === true) {
      setShouldRunNewShopLeadGeneration(true);
      setOrderPlacementAttempt(false);
    }
  }, [orderPlacementAttempt]);

  useEffect(() => {
    if (progress === 1) {
      setShowNoInventoryContent(true);
    } else if (progress === 2) {
      setShowErrorContent(true);
    } else if (progress === 100) {
      setShowMainContent(true);
    }
  }, [progress]);

  useEffect(() => {
    if (isDataComplete === false) {
      navigate("/");
    }
  }, []);

  function goToHome() {
    setProgress(0);
    setShowMainContent(false);
    setShowNoInventoryContent(false);
    resetData();
    setProgress(0)
    navigate("/");
  }
  
  function goToNewOrder() {
    setProgress(0);
    setShowMainContent(false);
    setShowNoInventoryContent(false);
    resetData();
    setProgress(0)
    navigate("/nuevo-pedido");
  }

  function copyOrder() {
    const str = `Fluenti. 
    Nuevo pedido #${
      newOrderData.public_id ? newOrderData.public_id : "0000-0000"
    }. 
    Total $${newOrderTotal}`;
    navigator.clipboard
      .writeText(str)
      .then(() => {
        console.log("Valor copiado al portapapeles");
        setCopy(true);
      })
      .catch((error) => {
        console.error("Error al copiar el valor al portapapeles:", error);
      });
  }

  useEffect(() => {}, [newOrderData]);

  return (
    <div className="fixed inset-0 bg-gradient-to-br from-green-50 to-blue-50 flex items-center justify-center p-4 px-8">
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-2xl w-full max-w-md p-8"
      >
        <AnimatePresence mode="wait">
          {!showMainContent && !showNoInventoryContent && !showErrorContent ? (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col items-center"
            >
              <h2 className="text-2xl font-medium text-gray-800 mb-4">
                Enviando pedido...
              </h2>
              <Progress.Root
                className="relative overflow-hidden bg-gray-200 rounded-full w-full h-4"
                style={{
                  transform: "translateZ(0)",
                }}
                value={progress}
              >
                <Progress.Indicator
                  className="bg-blue-500 w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
                  style={{ transform: `translateX(-${100 - progress}%)` }}
                />
              </Progress.Root>
            </motion.div>
          ) : showNoInventoryContent ? (
            <motion.div
              key="error"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col items-center"
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
              >
                <InfoIcon className="w-20 h-20 text-yellow-600 mb-6" />
              </motion.div>
              <motion.h2
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-3xl text-center font-medium text-gray-800 mb-2"
              >
                Error al procesar el pedido
              </motion.h2>
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="text-gray-600 text-center mb-4"
              >
                Lo sentimos, parece que tenemos pocos botellones, pero hemos
                tomado tu pedido, te contactaremos pronto.
              </motion.p>
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.5 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-6"
              >
                <Button size={"3"} onClick={goToHome}>
                  Volver al inicio
                </Button>
              </motion.div>
            </motion.div>
          ) : showErrorContent ? (
            <motion.div
              key="error"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col items-center"
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
              >
                <InfoIcon className="w-20 h-20 text-red-600 mb-6" />
              </motion.div>
              <motion.h2
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-3xl text-center font-medium text-gray-800 mb-2"
              >
                Error en el sistema
              </motion.h2>
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="text-gray-600 text-center mb-4"
              >
                Lo sentimos, ha ocurrido un error al procesar tu pedido. Por
                favor, intenta realizar un nuevo pedido.
              </motion.p>
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.5 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-6"
              >
                <Button size={"3"} onClick={goToNewOrder}>
                  Intentar nuevo pedido
                </Button>
              </motion.div>
            </motion.div>
          ) : (
            <motion.div
              key="success"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col items-center"
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
                className="mb-8"
              >
                <img
                  src="assets/logos/fluenti-logo-blue-140.svg"
                  alt=""
                  className="max-h-[80px]"
                />
              </motion.div>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
              >
                <CheckCircle className="w-20 h-20 text-blue-500 mb-6" />
              </motion.div>
              <motion.h2
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-3xl text-center font-medium text-gray-800"
              >
                ¡Recibimos tu pedido!
              </motion.h2>
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="text-gray-600 text-center p-0 m-0"
              >
                Muchas gracias, pronto te contactaremos para coordinar el pago y
                la entrega. Enviamos el ticket a tu correo.
              </motion.p>
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.5 }}
                className="text-2xl font-medium text-gray-900 cursor-pointer pt-8 pb-16"
                onClick={copyOrder}
              >
                <div className="flex items-center gap-2 shadow p-2 rounded-lg px-4 bg-gray-100">
                  <code>
                    Pedido{" "}
                    <span className="font-semibold">
                      #{newOrderData.public_id ? newOrderData.public_id : ""}
                    </span>
                  </code>
                  <Clipboard strokeWidth={2.75} size={"16"} />
                  {copy && <Check size={"12"} className="ms-[-8px]" />}
                </div>
              </motion.div>
              <motion.div
                className="flex items-center justify-center gap-2 w-full bg-transparent border-0"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Button size={"3"} variant="soft" onClick={goToHome}>
                    Ir al inicio
                  </Button>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Button
                    size={"3"}
                    className="font-medium"
                    onClick={goToNewOrder}
                  >
                    Realizar nuevo pedido
                  </Button>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
}
