import React, { useEffect } from "react";
import * as Form from "@radix-ui/react-form";
import { Button } from "@radix-ui/themes";
import Navbar from "../navigation/Navbar";
import DividerComponent from "../ui/DividerComponent";
import { useSupaContext } from "../../contexts/SupaContext"; // Ajusta la ruta según tu estructura de archivos
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { login, loading, error, user } = useSupaContext();
  console.log(">>>>>>>>>>>", user);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    await login(email, password);
  };

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user]);

  return (
    <div className="h-screen">
      <Navbar type="login" />
      <div className="w-screen min-h-full flex items-center justify-center">
        <div className="relative mx-auto bg-white rounded-3xl p-8 flex flex-col gap-8 max-w-[420px]">
          <div className="flex flex-col items-center justify-center gap-2">
            <img
              src="assets/logos/fluenti-logo-blue-140.svg"
              className="h-16"
              alt="Logo"
            />
            <p className="m-0 text-md font-normal text-center text-gray-500">
              Ingresa a tu cuenta, utiliza tu correo y contraseña o ingresa con
              Google.
            </p>
          </div>
          <Form.Root className="w-full" onSubmit={handleSubmit}>
            {error && (
              <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
                {error}
              </div>
            )}
            <Form.Field className="mb-4" name="email">
              <div className="flex items-baseline justify-between">
                <Form.Label className="font-light text-sm text-gray-600">
                  Correo electrónico
                </Form.Label>
              </div>
              <Form.Control asChild>
                <input
                  className="Input text-lg w-full p-2 border rounded"
                  type="email"
                  required
                  placeholder="Correo electrónico…"
                />
              </Form.Control>
              <Form.Message
                className="text-red-500 text-xs"
                match="valueMissing"
              >
                Por favor, ingrese su correo electrónico
              </Form.Message>
              <Form.Message
                className="text-red-500 text-xs"
                match="typeMismatch"
              >
                Por favor, ingrese un correo electrónico válido
              </Form.Message>
            </Form.Field>
            <Form.Field className="mb-4" name="password">
              <div className="flex items-baseline justify-between">
                <Form.Label className="font-light text-sm text-gray-600">
                  Contraseña
                </Form.Label>
              </div>
              <Form.Control asChild>
                <input
                  className="Input text-lg w-full p-2 border rounded"
                  type="password"
                  required
                  placeholder="Contraseña…"
                />
              </Form.Control>
              <Form.Message
                className="text-red-500 text-xs"
                match="valueMissing"
              >
                Por favor, ingrese su contraseña
              </Form.Message>
              <Form.Message
                className="text-red-500 text-xs"
                match={(value) => value.length < 8}
              >
                La contraseña debe tener al menos 8 caracteres
              </Form.Message>
            </Form.Field>
            <Form.Submit asChild>
              <Button
                className="w-full mt-5"
                size="3"
                variant="solid"
                disabled={loading}
              >
                {loading ? "Ingresando..." : "Ingresar"}
              </Button>
            </Form.Submit>
            <div className="text-center mt-12">
              <a
                href="/recuperar"
                className="text-sm text-gray-600 hover:text-gray-800"
              >
                ¿Olvidaste tu contraseña?
              </a>
            </div>
          </Form.Root>
          <DividerComponent
            color="fbfbfb"
            margin={0}
            size={4}
            direction="horizontal"
          />
          <div className="bg-gray-50 gap-3 hover:bg-gray-100 px-4 py-2 cursor-pointer border border-gray-400 rounded-3xl flex items-center justify-center">
            <img
              className="h-6"
              src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
              alt="Google logo"
            />
            <p className="text-lg">Ingresar con Google</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
