// RefillSection.jsx
import React from "react";
import { RefreshCcw } from "lucide-react";
import Stepper from "../../ui/Stepper";
import { useSalesContext } from "../../../contexts/SalesContext";
import { formatPrice } from "../../../scripts/generalScript";
import CollapsibleSection from "./CollapsibleSection";
import DividerComponent from "../../ui/DividerComponent";

const RefillSection = () => {
  const { selectedRefills, setSelectedRefills } = useSalesContext();

  return (
    <div className="bg-white flex flex-col mx-6 md:mx-10 rounded-xl gap-2">
      <div className="flex gap-3 items-center pb-6">
        <RefreshCcw className="flex gap-2 text-xl font-medium items-center bg-gray-800 p-2 text-blue-50 rounded-full" />
        <p className="text-2xl font-normal">Recambios</p>
      </div>

      <div className="flex justify-between items-center gap-8">
        <div className="flex gap-2 items-center">
          <img
            alt=""
            src="assets/img/landing/quickcart-01.png"
            className="max-h-[40px]"
          />
          <p className="m-0 text-gray-500">Recambios de botellón 20lt</p>
        </div>
        <Stepper
          value={selectedRefills}
          onChange={setSelectedRefills}
          min={1}
          max={30}
        />
      </div>
      <DividerComponent
        color="f0f0f0"
        margin={16}
        size={1}
        direction="horizontal"
      />
      <CollapsibleSection
        title="Total en recambios"
        amount={formatPrice(selectedRefills * 12990)}
      />
    </div>
  );
};

export default RefillSection;
