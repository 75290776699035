export function generateJugDummy() {
  const id = generateUUID();
  const userId = `user-${Math.floor(Math.random() * 1000)}`;
  const addressId = `address-${Math.floor(Math.random() * 1000)}`;
  const familyId = `family-${Math.floor(Math.random() * 1000)}`;
  const capacity = Math.random() * 10 + 1;
  const material = getRandomMaterial();
  const status = getRandomStatus();
  const type = getRandomType();
  const purchaseDate = getRandomDate();
  const expirationDate = getRandomDate();
  const createdAt = getRandomDateTime();
  const updatedAt = createdAt;

  return {
    id,
    user_id: userId,
    address_id: addressId,
    family_id: familyId,
    capacity: capacity.toFixed(2),
    material,
    status,
    type,
    purchase_date: purchaseDate,
    expiration_date: expirationDate,
    created_at: createdAt,
    updated_at: updatedAt,
  };
}

export function generateUUID() {
  return `${Math.floor(Math.random() * 1000000000).toString(16)}`;
}

function getRandomMaterial() {
  const materials = ["Glass", "Plastic", "Metal", "Ceramic"];
  return materials[Math.floor(Math.random() * materials.length)];
}

function getRandomStatus() {
  const statuses = ["free", "assigned", "in_use"];
  return statuses[Math.floor(Math.random() * statuses.length)];
}

function getRandomType() {
  const types = ["purchase", "rental"];
  return types[Math.floor(Math.random() * types.length)];
}

function getRandomDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = Math.floor(Math.random() * 12) + 1;
  const day = Math.floor(Math.random() * 28) + 1;
  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
}

function getRandomDateTime() {
  const date = new Date();
  const year = date.getFullYear();
  const month = Math.floor(Math.random() * 12) + 1;
  const day = Math.floor(Math.random() * 28) + 1;
  const hour = Math.floor(Math.random() * 24);
  const minute = Math.floor(Math.random() * 60);
  const second = Math.floor(Math.random() * 60);
  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")} ${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}:${second.toString().padStart(2, "0")}`;
}

export function formatPrice(price) {
  if (typeof price === "string") {
    price = parseFloat(price.replace(/[^\d\.]/g, ""));
  }
  return price.toLocaleString("es-ES", { useGrouping: true, separator: "." });
}

export const processGeoJSON = (geoJSON) => {
  try {
    const features = geoJSON.features;
    if (!features || features.length === 0) {
      throw new Error("No se encontraron características en el GeoJSON");
    }

    const polygonFeature = features.find(
      (feature) => feature.geometry.type === "Polygon"
    );
    if (!polygonFeature) {
      throw new Error("No se encontró un polígono en el GeoJSON");
    }

    const coordinates = polygonFeature.geometry.coordinates[0];
    const polygonPath = coordinates.map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }));

    // Calcular el centro del polígono
    const sumLat = polygonPath.reduce((sum, coord) => sum + coord.lat, 0);
    const sumLng = polygonPath.reduce((sum, coord) => sum + coord.lng, 0);
    const center = {
      lat: sumLat / polygonPath.length,
      lng: sumLng / polygonPath.length,
    };

    return {
      polygonPath,
      center,
    };
  } catch (error) {
    console.error("Error al procesar el GeoJSON:", error);
    return null;
  }
};

export function isValidEmail(email) {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
}

export function handlePhoneChange(event, setPhone) {
  let input = event.target.value;

  // Si el input ya tiene +57, lo eliminamos para evitar duplicados
  if (input.startsWith("+57")) {
    input = input.slice(3).trim();
  }

  // Limpiamos el input dejando solo los números
  const numbers = input.replace(/\D/g, "");

  // Formateamos el número dejando +57 fijo y agregando los espacios
  let formatted = "+57 ";
  if (numbers.length > 0) {
    formatted += numbers.slice(0, 3); // Los primeros 3 dígitos
  }
  if (numbers.length >= 4) {
    formatted += " " + numbers.slice(3, 6); // Los siguientes 3 dígitos
  }
  if (numbers.length >= 7) {
    formatted += " " + numbers.slice(6, 10); // Los últimos 4 dígitos
  }

  setPhone(formatted);
}

export function handleEmailChange(event, setEmail) {
  const newEmail = event.target.value;
  setEmail(newEmail);

  // You can add more complex validation if needed
  if (isValidEmail(newEmail)) {
    // Email is valid, you can update other states or perform actions
  } else {
    // Email is invalid, you can set an error state or show a message
  }
}

export const capitalizeWords = (str) => {
  return str.replace(
    /\b\w{3,}/g,
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
};

export const handleNameChange = (event, setName) => {
  const input = event.target.value;
  const sanitizedInput = input.replace(/[^a-zA-Z\s]/g, "");
  const capitalizedInput = capitalizeWords(sanitizedInput);
  setName(capitalizedInput);
};

export function cleanStringNumber(string) {
  return string.replace(/\D+/g, "").replace(/\s+/g, "");
}

export const generateEpochUniqueId = (() => {
  let lastTimestamp = 0;
  let sequence = 0;

  return () => {
    let timestamp = new Date();

    if (timestamp === lastTimestamp) {
      sequence++;
    } else {
      sequence = 0;
      lastTimestamp = timestamp;
    }

    // Combina el timestamp con la secuencia
    const combined = timestamp * 1000 + sequence;

    // Convierte a base 36, toma los últimos 8 caracteres, rellena con ceros y convierte a mayúsculas
    return combined.toString(36).slice(-8).padStart(8, "0").toUpperCase();
  };
})();

export const sendLeadToWebhook = async (WEBHOOK_URL, leadData) => {
  try {
    const response = await fetch(WEBHOOK_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(leadData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    console.log("Lead enviado con éxito al webhook");
  } catch (error) {
    console.error("Error al enviar el lead al webhook:", error);
  }
};
