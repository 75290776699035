import React, { useState, useEffect, useRef } from "react";
import { useSalesContext } from "../../../contexts/SalesContext";
import { Loader, TrashIcon, ArrowDownIcon, MapPinPlus } from "lucide-react";
import * as Popover from "@radix-ui/react-popover";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import * as Collapsible from "@radix-ui/react-collapsible";
import polygonData from "../../pages/coverageComponents/perimeter.json";
import {
  cleanStringNumber,
  sendLeadToWebhook,
} from "../../../scripts/generalScript";
import * as Switch from "@radix-ui/react-switch";
import DividerComponent from "../../ui/DividerComponent";
import { useSupaContext } from "../../../contexts/SupaContext";

const GOOGLE_MAPS_API_KEY = "AIzaSyAC56gt3Q7GNrcq-SfEaBVrcjLfFGqPN0Y";
const WEBHOOK_URL =
  "https://hook.us1.make.com/vyi9sj1adobd7p6mc99do9ybxd2s1o9a";

const loadGoogleMapsApi = () => {
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geometry`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
  return new Promise((resolve) => {
    script.onload = resolve;
  });
};

export default function AddressInput() {
  const { createAddressAfterCreateUser, addPreRegistration } = useSupaContext();
  const {
    selectedSugs,
    selectedRefills,
    name,
    phone,
    email,
    addressDetails,
    setAddressDetails,
    selectedAddress,
    setSelectedAddress,
    addressHasDetails,
    setAddressHasDetails,
    selectedCoordinates,
    setSelectedCoordinates,
  } = useSalesContext();

  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isValid, setIsValid] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const timeoutRef = useRef(null);
  const inputRef = useRef(null);
  const popoverRef = useRef(null);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);
  const polygonRef = useRef(null);
  const [isManualChange, setIsManualChange] = useState(true);
  const addressDetailsRef = useRef(null);
  const addressDetailsInputRef = useRef(null);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    loadGoogleMapsApi().then(() => {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      const polygonCoords = polygonData.features[0].geometry.coordinates[0].map(
        (coord) => new window.google.maps.LatLng(coord[1], coord[0])
      );
      polygonRef.current = new window.google.maps.Polygon({
        paths: polygonCoords,
      });
    });
  }, []);

  useEffect(() => {
    if (!isManualChange) {
      setIsManualChange(true);
      return;
    }

    if (input.length > 2) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setIsLoading(true);
      timeoutRef.current = setTimeout(() => {
        fetchSuggestions();
      }, 1800);
    } else {
      setSuggestions([]);
      setOpen(false);
      setIsLoading(false);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line
  }, [input]);

  useEffect(() => {
    if (
      isValid &&
      addressDetailsRef.current &&
      addressDetailsInputRef.current
    ) {
      addressDetailsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      // Pequeño retraso para asegurar que el scroll ha terminado
      setTimeout(() => {
        addressDetailsInputRef.current.focus();
      }, 400);
    }
  }, [isValid]);

  const fetchSuggestions = () => {
    if (!autocompleteService.current) return;

    const request = {
      input: input,
      componentRestrictions: { country: "CO" },
      bounds: new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(10.9486, -74.8366),
        new window.google.maps.LatLng(11.0344, -74.7634)
      ),
      types: ["address"],
      strictBounds: true,
    };

    autocompleteService.current.getPlacePredictions(
      request,
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const barranquillaSuggestions = results.filter((result) =>
            result.description.toLowerCase().includes("barranquilla")
          );
          setSuggestions(barranquillaSuggestions);
          setNoResults(barranquillaSuggestions.length === 0);
          setOpen(barranquillaSuggestions.length > 0 || noResults);
        } else {
          setSuggestions([]);
          setNoResults(true);
          setOpen(true);
        }
        setIsLoading(false);
      }
    );
  };

  const handleSuggestionClick = (suggestion) => {
    setIsManualChange(false);
    setInput(suggestion.description);
    setSelectedAddress(suggestion.description);
    setSuggestions([]);
    setOpen(false);

    placesService.current.getDetails(
      { placeId: suggestion.place_id },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          validateLocation(coordinates, suggestion.description);
          setSelectedCoordinates(coordinates);
        }
      }
    );

    inputRef.current.focus();
  };

  const validateLocation = async (coordinates, selectedSuggestion) => {
    if (!isGoogleMapsInitialized()) {
      console.error("Google Maps or polygon not initialized");
      return;
    }

    const isInside = checkIfLocationIsInside(coordinates);
    setIsValid(isInside);

    if (!isInside) {
      const leadData = prepareLeadData(coordinates);

      try {
        await sendLeadToWebhook(WEBHOOK_URL, leadData);

        const preRegistrationData = {
          first_name: name.split(" ")[0],
          last_name: name.split(" ").slice(1).join(" ") || "Desconocido",
          phone: cleanStringNumber(phone),
          email,
        };

        const preRegisteredUser = await addPreRegistration(preRegistrationData);

        if (preRegisteredUser && preRegisteredUser.id) {
          const addressData = {
            user_id: preRegisteredUser.id,
            city: "Barranquilla",
            state: "ATL",
            country: "Colombia",
            latitude: coordinates.lat,
            longitude: coordinates.lng,
            address: selectedSuggestion,
            details: addressDetails,
            pre_registration: true,
          };

          await createAddressAfterCreateUser(addressData);
        } else {
          console.error(
            "No se pudo completar el pre-registro o no se obtuvo un ID de usuario"
          );
        }
      } catch (error) {
        console.error("Error en validateLocation:", error.message);
      }
    }
  };

  const isGoogleMapsInitialized = () => {
    return window.google && window.google.maps && polygonRef.current;
  };

  const checkIfLocationIsInside = (coordinates) => {
    const latLng = new window.google.maps.LatLng(
      coordinates.lat,
      coordinates.lng
    );
    return window.google.maps.geometry.poly.containsLocation(
      latLng,
      polygonRef.current
    );
  };

  const prepareLeadData = () => {
    const cleanPhone = cleanStringNumber(phone);
    return {
      name,
      cleanPhone,
      email,
      address: input,
      selectedCoordinates,
      selectedSugs,
      selectedRefills,
      timestamp: new Date().toISOString(),
    };
  };

  const handleInputChange = (e) => {
    const newInput = e.target.value;
    setInput(newInput);
    setSelectedAddress(newInput);

    if (newInput !== selectedAddress) {
      setSelectedAddress("");
      setSelectedCoordinates(null);
      setIsValid(null);
      setAddressDetails("");
    }
  };

  const clearAddress = () => {
    setInput("");
    setSelectedAddress("");
    setAddressDetails("");
    setSuggestions([]);
    setIsValid(null);
    setSelectedCoordinates(null);
    setOpen(false);
    setNoResults(false);
    setIsLoading(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleAddressDetailsChange = (e) => {
    setAddressDetails(e.target.value);
  };

  const handleAddressHasDetailsChange = (checked) => {
    setAddressHasDetails(checked);
    if (!checked) {
      setAddressDetails("");
    }
  };

  return (
    <div className="w-full max-w-full">
      <div className="relative">
        <div className="flex items-baseline justify-between">
          <label
            className="font-light text-sm text-gray-600"
            htmlFor="address-field"
          >
            Dirección de entrega
          </label>
        </div>

        <div className="relative">
          <input
            ref={inputRef}
            id="address-field"
            type="text"
            value={input}
            onChange={handleInputChange}
            placeholder="Ej. Calle 50 # 46-10"
            className="text-lg w-full p-2 border rounded-md bg-gray-50 mt-2"
            autoComplete="new-address"
            name="new-address"
          />
        </div>

        {/* Indicador de carga o sin resultados */}
        <div className="flex gap-2 items-center pt-2">
          {isLoading ? (
            <>
              Buscando
              <Loader className="animate-spin" size={16} />
            </>
          ) : noResults ? (
            <>
              <p className="p-0 m-0">Sin sugerencias.</p>
              <p
                className="p-0 m-0 flex gap-1 items-center font-medium"
                onClick={clearAddress}
              >
                Borrar
                <TrashIcon size={12} />
              </p>
            </>
          ) : null}
        </div>

        {/* Contenedor fijo para sugerencias */}
        {suggestions.length > 0 && (
          <div className="left-0 right-0 bg-white border mt-1 shadow">
            <div className="max-h-60 ">
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion.place_id}
                  onClick={() => {
                    handleSuggestionClick(suggestion);
                  }}
                  className="p-2 hover:bg-gray-100 cursor-pointer border-b last:border-b-0"
                >
                  {suggestion.description}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Mensaje de validación */}
      {!isValid && selectedAddress !== "" && selectedCoordinates &&(
        <div className="text-left p-2 rounded-md mt-4 bg-red-100 text-red-800">
          <p className="p-0 m-0 flex gap-1">
            Lo sentimos, estamos trabajando para poder entregar a tu dirección.
            <span
              className="p-0 m-0 flex gap-1 items-center font-medium"
              onClick={clearAddress}
            >
              Borrar
              <TrashIcon size={12} />
            </span>
          </p>
        </div>
      )}
      {/* Mapa y detalles adicionales */}
      {selectedCoordinates && isValid && (
        <>
          <div className="w-full h-48 mt-4 rounded-md overflow-hidden flex items-center justify-center relative">
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${selectedCoordinates.lat},${selectedCoordinates.lng}&zoom=18&size=640x420&maptype=roadmap&markers=color:red%7C${selectedCoordinates.lat},${selectedCoordinates.lng}&key=${GOOGLE_MAPS_API_KEY}`}
              alt="Mapa estático de la dirección"
              className="w-full h-full object-cover rounded-md absolute left-0 top-0 filter contrast-125 brightness-95"
              style={{
                filter:
                  "contrast(1.3) brightness(0.9) saturate(1.2) sharpen(1)",
              }}
            />
            <div
              className="left-4 top-0 absolute text-left px-2 py-1 rounded-md mt-4 bg-green-100 text-green-800 border-5 border-white"
              style={{ border: "4px solid #fff" }}
            >
              <p className="p-0 m-0 flex gap-1">
                Podemos entregar en tu dirección
              </p>
            </div>
          </div>

          <div className="mt-4 flex items-center gap-4 py-4">
            <MapPinPlus />
            <label
              className="font-medium text-md text-gray-800"
              htmlFor="address-has-details"
            >
              La dirección tiene detalles adicionales
            </label>
            <Switch.Root
              className="custom-switch-root"
              id="address-has-details"
              checked={addressHasDetails}
              onCheckedChange={handleAddressHasDetailsChange}
            >
              <Switch.Thumb className="custom-switch-thumb" />
            </Switch.Root>
          </div>

          {addressHasDetails && (
            <div className="mt-2">
              <label
                className="font-light text-sm text-gray-600"
                htmlFor="address-details"
              >
                Detalles de la dirección
              </label>
              <input
                id="address-details"
                ref={addressDetailsInputRef}
                type="text"
                value={addressDetails}
                onChange={handleAddressDetailsChange}
                placeholder="Apartamento, oficina, referencia, etc."
                className="text-lg w-full p-2 border rounded-md bg-gray-50 mt-2"
                autoComplete="off"
              />
            </div>
          )}

          <DividerComponent
            color="f0f0f0"
            margin={32}
            size={1}
            direction="horizontal"
          />

          <Collapsible.Root>
            <Collapsible.Trigger asChild>
              <div className="flex justify-between items-end gap-0 my-4">
                <p className="p-0 m-0 text-lg font-medium">Costos de entrega</p>
                <div className="flex flex-col justify-end items-end">
                  <p className="flex justify-between text-xl font-medium p-0 m-0">
                    $ Gratis
                  </p>
                  <p className="p-0 m-0 text-xs bg-transparent w-full flex items-center justify-end gap-1 text-gray-400">
                    Detalles de este cobro <ArrowDownIcon size={12} />
                  </p>
                </div>
              </div>
            </Collapsible.Trigger>
            <Collapsible.Content>sdsd</Collapsible.Content>
          </Collapsible.Root>
        </>
      )}
    </div>
  );
}
