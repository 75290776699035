// supabaseClient.js
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://vacewaenygdaqchlrsam.supabase.co"; // Valor por defecto para desarrollo
//
//
// const supabaseAnonKey = process.env.SUPABASE_KEY;
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZhY2V3YWVueWdkYXFjaGxyc2FtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY5NjA3MjksImV4cCI6MjA0MjUzNjcyOX0.uoVWgoy5PnOYn7unO0vBnGQ64hl_y-_anF_aTEnIPGM";

if (!supabaseUrl || !supabaseAnonKey) {
  console.error("Supabase environment variables are not set correctly.");
} else {
  console.log("Success connect supabase");
}

const supabase = createClient(supabaseUrl, supabaseAnonKey);

export default supabase;
