import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useInventory } from "./InventoryContext";
import { useSupaContext } from "./SupaContext";
import { parseFullName } from "../scripts/nameHandler";
import {
  cleanStringNumber,
  generateEpochUniqueId,
} from "../scripts/generalScript";

export const SalesContext = createContext();

export const useSalesContext = () => useContext(SalesContext);

export const SalesProvider = ({ children }) => {
  const { getAvailableBottles } = useInventory();
  const {
    createAddressAfterCreateUser,
    addPreRegistration,
    createSubscriptionsForSugs,
    createOrder,
    createOrderItemsWithSugs,
    createMultipleOrderItems,
    createBulkRefills,
  } = useSupaContext();
  const valorSuscripcion = 9990;
  const valorRecambio = 13990;

  const [selectedSugs, setSelectedSugs] = useState(1);
  const [selectedRefills, setSelectedRefills] = useState(1);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("+57 ");
  const [email, setEmail] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [addressDetails, setAddressDetails] = useState("");
  const [newOrderTotal, setNewOrderTotal] = useState(
    selectedSugs * valorSuscripcion + selectedRefills * valorRecambio
  );

  const [addressHasDetails, setAddressHasDetails] = useState(true);
  const [newSalesTerms, setNewSalesTerms] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [progress, setProgress] = useState(0);
  const [shortId, setShortId] = useState(generateEpochUniqueId);
  const [isDataComplete, setIsDataComplete] = useState(false);
  const [newOrderData, setNewOrderData] = useState();
  

  useEffect(() => {
    const checkDataComplete = () => {
      const isComplete =
        name !== "" &&
        phone !== "+57 " &&
        email !== "" &&
        selectedAddress !== "" &&
        selectedCoordinates !== null &&
        newSalesTerms === true;

      setIsDataComplete(isComplete);
    };

    checkDataComplete();
  }, [name, phone, email, selectedAddress, selectedCoordinates, newSalesTerms]);

  const [shouldRunNewShopLeadGeneration, setShouldRunNewShopLeadGeneration] =
    useState(false);
  const hasRunNewShopLeadGeneration = useRef(false);

  useEffect(() => {
    setNewOrderTotal(
      selectedSugs * valorSuscripcion + selectedRefills * valorRecambio
    );
  }, [selectedSugs, selectedRefills]);

  useEffect(() => {
    if (shouldRunNewShopLeadGeneration === true) {
      newShopLeadGeneration();
      setShouldRunNewShopLeadGeneration(false);
    }
  }, [shouldRunNewShopLeadGeneration]);

  const resetData = () => {
    setSelectedSugs(1);
    setSelectedRefills(1);
    setName("");
    setPhone("+57 ");
    setEmail("");
    setSelectedAddress("");
    setAddressDetails("");
    setAddressHasDetails(true);
    setNewSalesTerms(false);
    setSelectedCoordinates(null);
    hasRunNewShopLeadGeneration.current = false;
    setShouldRunNewShopLeadGeneration(false);
    setIsDataComplete(false);
    setShortId(generateEpochUniqueId);
  };

  const newShopLeadGeneration = async () => {
    setProgress(0)
    try {
      setProgress(10);
      const splitedName = parseFullName(name);

      // 1. CREAR USUARIO (PRE-REGISTRO)
      const preRegistrationData = {
        first_name: splitedName.firstNames,
        last_name: splitedName.lastNames,
        phone: cleanStringNumber(phone),
        email,
      };
      setProgress(15);

      const preRegisteredUser = await addPreRegistration(
        preRegistrationData
      ).catch((error) => {
        console.error("Error en pre-registro:", error.message);
        setProgress(2);
        resetData();
        return null;
      });

      if (preRegisteredUser) {
        setProgress(25);
        await nextStepBreak(300);

        // 2. CREAR DIRECCIÓN
        const userId = preRegisteredUser.id;

        const addressData = {
          user_id: userId,
          city: "Barranquilla",
          state: "ATL",
          country: "Colombia",
          latitude: selectedCoordinates.lat,
          longitude: selectedCoordinates.lng,
          address: selectedAddress,
          details: addressDetails,
          pre_registration: true,
        };

        const orderAddress = await createAddressAfterCreateUser(
          addressData
        ).catch((error) => {
          console.error("Error creando dirección:", error.message);
          setProgress(2);
          resetData();
          return null;
        });

        if (orderAddress) {
          console.log("Dirección creada:", orderAddress);
          setProgress(45);
          await nextStepBreak(300);

          // 3. OBTENER BOTELLAS DISPONIBLES
          const sugs = await getAvailableBottles(selectedSugs).catch(
            (error) => {
              console.error("Error obteniendo botellas:", error.message);
              resetData();
              setProgress(1);
              return null;
            }
          );

          if (sugs && sugs.length === selectedSugs) {
            console.log("Botellas disponibles:", sugs);

            // 4. CREAR ORDEN
            const orderData = {
              userId: userId,
              public_id: shortId,
              total: newOrderTotal,
              terms: true,
            };

            const newOrder = await createOrder(orderData).catch((error) => {
              console.error("Error creando orden:", error.message);
              setProgress(2);
              resetData();
              return null;
            });

            if (newOrder) {
              setNewOrderData(newOrder)
              console.log('>>>>>>>>>>>new order', newOrder)
              // 5. CREAR SUSCRIPCIONES
              const baseSubscriptionData = {
                userId: userId,
                addressId: orderAddress.id,
                monthly_fee: valorSuscripcion,
                status: "dealPending",
                order_id:newOrder.id
              };
              console.log('>>>>>>>>>>>la base de la orden', baseSubscriptionData)
              const subscriptionsCreated = await createSubscriptionsForSugs(
                sugs,
                baseSubscriptionData
              ).catch((error) => {
                console.error("Error creando suscripciones:", error.message);
                setProgress(2);
                resetData();
                return null;
              });

              if (subscriptionsCreated) {
                console.log("Suscripciones creadas para botellas:", sugs);
                setProgress(65);
                await nextStepBreak(300);

                // 6. CREAR ITEMS DE ORDEN PARA SUGS
                const sugItemsData = {
                  item_type: "sug_subscription",
                  qty: 1,
                  value: Number(valorSuscripcion),
                };

                const sugItems = await createOrderItemsWithSugs(
                  newOrder,
                  sugs,
                  sugItemsData
                ).catch((error) => {
                  console.error(
                    "Error creando items de suscripción:",
                    error.message
                  );
                  setProgress(2);
                  resetData();
                  return null;
                });

                if (sugItems) {
                  console.log("Items de suscripción creados:", sugItems);
                  setProgress(75);
                  await nextStepBreak(300);

                  // 7. CREAR REFILLS
                  const refillsResult = await createBulkRefills(
                    selectedRefills,
                    userId,
                    newOrder.id
                  ).catch((error) => {
                    console.error("Error creando refills:", error.message);
                    setProgress(2);
                    resetData();
                    return null;
                  });

                  if (refillsResult?.success) {
                    console.log("Refills creados:", refillsResult);
                    setProgress(85);
                    await nextStepBreak(300);

                    // 8. CREAR ITEM DE ORDEN PARA REFILLS
                    const refillItem = {
                      order_id: newOrder.id,
                      order_public_id: shortId,
                      item_type: "individual_refill",
                      qty: 1,
                      value: Number(valorRecambio),
                    };
                    const refillOrderItems = await createMultipleOrderItems(
                      refillItem,
                      refillsResult.refillIds
                    ).catch((error) => {
                      console.error(
                        "Error creando items de refills:",
                        error.message
                      );
                      setProgress(2);
                      resetData();
                      return null;
                    });

                    if (refillOrderItems) {
                      console.log("Item de refills creado:", refillOrderItems);
                      setProgress(95);
                      await nextStepBreak(300);
                      
                      // FINALIZACIÓN EXITOSA
                      setProgress(100);
                      resetData();
                      console.log("Proceso completado exitosamente");
                      await nextStepBreak(800);
                      return true;
                    }
                  }
                }
              }
            }
          } else {
            setProgress(1);
            console.error(
              `Se requieren ${selectedSugs} botellas, pero solo hay ${
                sugs?.length || 0
              } disponibles`
            );
          }
        }
      }
      // Si llegamos aquí es porque algo falló en el camino
      resetData();
      return false;
    } catch (error) {
      console.error("Error general en newShopLeadGeneration:", error.message);
      setProgress(2);
      resetData();
      setProgress(0);
      return false;
    }
  };

  const nextStepBreak = (duration) => {
    return new Promise((resolve) => setTimeout(resolve, duration));
  };

  const triggerNewShopLeadGeneration = () => {
    setShouldRunNewShopLeadGeneration(true);
  };

  const resetNewShopLeadGenerationFlag = () => {
    hasRunNewShopLeadGeneration.current = false;
  };

  const contextValue = {
    selectedRefills,
    selectedSugs,
    setSelectedRefills,
    setSelectedSugs,
    name,
    setName,
    phone,
    setPhone,
    email,
    setEmail,
    selectedAddress,
    setSelectedAddress,
    addressDetails,
    setAddressDetails,
    newOrderTotal,
    addressHasDetails,
    setAddressHasDetails,
    newSalesTerms,
    setNewSalesTerms,
    selectedCoordinates,
    setSelectedCoordinates,
    resetData,
    progress,
    setProgress,
    triggerNewShopLeadGeneration,
    resetNewShopLeadGenerationFlag,
    setShouldRunNewShopLeadGeneration,
    shortId,
    newOrderData,
    isDataComplete,
  };

  return (
    <SalesContext.Provider value={contextValue}>
      {children}
    </SalesContext.Provider>
  );
};
