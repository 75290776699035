import React, { useState, useEffect } from "react";
import { Menu, RefreshCw } from "lucide-react";
import { Button } from "@radix-ui/themes";
import Sidepanel from "./Sidepanel";

const NavItem = ({ item, isScrolled, openSidebar }) => {
  if (item.href) {
    return (
      <a
        href={item.href}
        className={`pe-3 py-2 rounded-md font-regular text-base no-underline ${
          isScrolled ? "text-gray-800" : "text-white"
        }`}
      >
        {item.name}
      </a>
    );
  } else if (item.onClick || item.sidebarType) {
    return (
      <p
        className={`pe-3 py-2 rounded-md font-regular text-base no-underline cursor-pointer ${
          isScrolled ? "text-gray-800" : "text-white"
        }`}
        onClick={() =>
          item.sidebarType ? openSidebar(item.sidebarType) : item.onClick()
        }
      >
        {item.name}
      </p>
    );
  }
  return null;
};

export default function Navbar({ type = "general", staticPosition = false }) {
  const [activeSidebar, setActiveSidebar] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const openSidebar = (sidebarType) => {
    setActiveSidebar(sidebarType);
  };

  const closeSidebar = () => {
    setActiveSidebar(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navItems = [
    { name: "Pedir botellón", onClick: manageScroll },
    { name: "Cobertura", sidebarType: "coverage" },
    // Puedes añadir más items aquí, con href, onClick, o sidebarType según necesites
  ];

  function manageScroll() {
    const elementId = "hero";
    const element = document.getElementById(elementId);

    if (element) {
      const top = element.offsetTop;
      const duration = 500; // tiempo en milisegundos para el desplazamiento suave

      window.scrollTo({
        top,
        behavior: "smooth",
      });
    }
  }

  const renderDefault = () => (
    <div className="flex items-center justify-between container">
      <a className="flex items-center" href="/">
        <img
          src="assets/logos/fluenti-logo-blue-140.svg"
          className="logo-image"
          alt="Fluenti Logo"
        />
      </a>
      <div className="flex gap-5 items-center">
        <div className="hidden md:flex items-baseline no-underline gap-4">
          {navItems.map((item, index) => (
            <NavItem
              key={index}
              item={item}
              isScrolled={isScrolled}
              openSidebar={openSidebar}
            />
          ))}
        </div>
        <a href="/ingresar">
          <Button size="3" className="bg-white text-blue-800 flex gap-1 px-3">
            Ingresar
          </Button>
        </a>
        <Button size="3" className="bg-white text-blue-800 flex gap-1 px-3">
          <RefreshCw size={16} />
          Pedir recambio
        </Button>
      </div>
    </div>
  );

  const renderLogin = () => (
    <div className="mx-auto container px-8 py-5 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between h-16">
        <a href="/">
          <img
            src="assets/logos/fluenti-logo-blue-140.svg"
            className="logo-image"
            alt="Fluenti Logo"
          />
        </a>
        <a href="/registro">
          <Button size={3} variant="surface">
            Crea tu cuenta
          </Button>
        </a>
      </div>
    </div>
  );

  const renderNewOrder = () => (
    <div className="flex justify-between w-[100vw] mx-4 px-2 items-center">
      <img
        src="assets/logos/fluenti-logo-blue-140.svg"
        className="logo-image"
        alt="Fluenti Logo"
      />
      <p className="text-2xl font-medium text-gray-900 text-right">
        Realizar nuevo pedido
      </p>
    </div>
  );

  const content =
    type === "login"
      ? renderLogin()
      : type === "new-order"
      ? renderNewOrder()
      : renderDefault();

  return (
    <div
      className={`transition-all ${
        staticPosition === true ? "shadow-sm" : "fixed"
      } z-20 duration-300 ease-in-out ${
        isScrolled
          ? "bg-white shadow-navbar text-blue-700"
          : "bg-transparent text-white"
      }`}
      style={{
        minWidth: "100vw",
        maxWidth: "100vw",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {content}
      {activeSidebar && (
        <Sidepanel
          isOpen={!!activeSidebar}
          onClose={closeSidebar}
          type={activeSidebar}
        />
      )}
    </div>
  );
}
